<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true">
            Price Margins
            <template slot="actions">
                <mercur-button data-test="marginsAddNewButton" @click="triggerAdd" class="btn btn-primary btn-raised">
                    <span>Add new price margin table</span>
                </mercur-button>
            </template>
        </grid-header>
        <mercur-card class="bg-primary" style="min-height: 92px;">
            <pretty-select
                data-test="marginsSelector"
                v-if="priceMargins.length && !loading"
                v-model="selectedPriceMargin"
                :options="priceMargins"
                label="priceMarginName"
                placeholder="Select existing margin table"
                color="white"
            >
                <template slot="option" slot-scope="option">
                    {{ type(option.priceMarginType) }} # {{ option.priceMarginName }}
                </template>
                <template slot="selected-option" slot-scope="option">
                    {{ type(option.priceMarginType) }} # {{ option.priceMarginName }}
                </template>
            </pretty-select>
            <mercur-spinner data-e2e="mappingsSpinnerLoader" v-else-if="loading" />
        </mercur-card>
        <div v-if="selectedPriceMargin" class="mx-4">
            <div class="row">
                <div class="col">
                    <p class="fs-16 mt-20">{{ selectedPriceMargin.priceMarginDescription }}</p>
                    <p>
                        <mercur-button data-test="marginsEditButton" class="btn btn-outline-blue" @click="triggerEdit" :disabled="editingMarginTable || loading"><i class="fas fa-pen"></i>Edit details</mercur-button>
                        <mercur-button data-test="marginsDuplicateButton" class="btn btn-outline-blue" @click="triggerDuplicate" :disabled="editingMarginTable || loading"><i class="fas fa-clone"></i>Duplicate table</mercur-button>
                        <mercur-button data-test="marginsDeleteButton" class="btn btn-danger btn-raised m-0 u-float-right" @click="triggerDelete" :disabled="editingMarginTable || loading">Delete price margin</mercur-button>
                    </p>
                    <hr>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col d-flex justify-content-end align-items-center">
                    <mercur-button data-test="marginsRevertTableButton" class="btn btn-outline-blue mr-1" v-if="editingMarginTable" @click="triggerUndo" :disabled="loading"><i class="fas fa-undo"></i> Revert changes</mercur-button>
                    <mercur-button data-test="marginsEditTableButton" class="btn btn-raised" @click="triggerEditMargins" :disabled="loading">{{editingMarginTable ? 'Cancel' : 'Edit table'}}</mercur-button>
                    <mercur-button data-test="marginsEditTableSaveButton" class="btn btn-raised btn-success" @click="updateMarginTable" :disabled="!editingMarginTable || loading">Save changes</mercur-button>
                </div>
            </div>
            <div class="row" v-if="selectedPriceMargin.priceMarginTable">
                <div class="col-6">
                    <table class="margin-table margin-table--stripped" :class="{'margin-table--selectable':  editingMarginTable}">
                        <thead>
                            <tr>
                                <th class="margin-table__checkbox" v-if="editingMarginTable"><mercur-checkbox color="purple" v-model="selectAll"></mercur-checkbox></th>
                                <template v-if="selectedRows.length > 0">
                                    <th colspan="3">
                                        <mercur-tooltip>
                                            <template slot="label">Delete selected rows</template>
                                            <mercur-button @click="deleteSelectedRows" class="btn btn-icon">
                                                <i class="fas fa-trash"></i>
                                            </mercur-button>
                                        </mercur-tooltip>
                                    </th>
                                </template>
                                <template v-else>
                                    <th>{{ type(selectedPriceMargin.priceMarginType) }}</th>
                                    <th colspan="2">Margin</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  v-for="(item, index) in selectedPriceMargin.priceMarginTable" :key="index">
                                <td class="margin-table__checkbox" v-if="editingMarginTable"><mercur-checkbox color="purple" v-model="selectedRows" :value="item.temporaryId"></mercur-checkbox></td>
                                <td>
                                    <label v-if="editingMarginTable">To</label>
                                    <template v-if="editingMarginTable">
                                        <mercur-input data-test="marginTableToInput" v-model.trim="item.to" />
                                        <span class="error" v-if="$v.selectedPriceMargin.$dirty && !$v.selectedPriceMargin.priceMarginTable.$each[index].to.required">Required</span>
                                        <span class="error" v-if="$v.selectedPriceMargin.$dirty && !$v.selectedPriceMargin.priceMarginTable.$each[index].to.numeric">Needs to be numeric</span>
                                        <span class="error" v-if="$v.selectedPriceMargin.$dirty && !$v.selectedPriceMargin.priceMarginTable.$each[index].to.minValue">Must be bigger than 0!</span>
                                        <span class="error" v-if="$v.selectedPriceMargin.$dirty && !$v.selectedPriceMargin.priceMarginTable.$each[index].to.unique">Has to be unique value!</span>
                                    </template>
                                    <template v-else>{{ item.to }}</template>
                                </td>
                                <td>
                                    <template v-if="editingMarginTable">
                                        <div class="position-relative"><span class="percentage">%</span><mercur-input data-test="marginTableMarginInput" v-model.trim="item.margin" /></div>
                                        <span class="error" v-if="$v.selectedPriceMargin.$dirty && !$v.selectedPriceMargin.priceMarginTable.$each[index].margin.required">Required<br></span>
                                        <span class="error" v-if="$v.selectedPriceMargin.$dirty && !$v.selectedPriceMargin.priceMarginTable.$each[index].margin.decimal">Needs to be numeric<br></span>
                                        <span class="error" v-if="$v.selectedPriceMargin.$dirty && !$v.selectedPriceMargin.priceMarginTable.$each[index].margin.between">Needs to be between -100 and 100</span>
                                    </template>
                                    <template v-else>{{ item.margin }}%</template>
                                </td>
                                <td v-if="editingMarginTable">
                                    <mercur-menu>
                                        <mercur-button data-test="marginsTableRowOptionsButton" class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                                        <div slot="dropdown">
                                            <mercur-item @click.native="insertRowAbove(index)">Insert row above</mercur-item>
                                            <mercur-item @click.native="insertRowBelow(index)">Insert row below</mercur-item>
                                            <hr class="m-0">
                                            <mercur-item data-test="marginsDeleteTableRowButton" @click.native="deleteRow(item.temporaryId)">Delete row</mercur-item>
                                        </div>
                                    </mercur-menu>
                                </td>
                            </tr>
                            <tr v-if="editingMarginTable">
                                <td data-test="marginsEditingTableAddRowButton" @click="insertRowBelow(selectedPriceMargin.priceMarginTable.length - 1)" class="u-text-center" colspan="4">
                                    <mercur-button class="btn btn-outline-blue font-weight-bold"><i class="fas fa-plus-square"></i> Add row</mercur-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <mercur-dialog data-test="marginsDeleteModal" :is-open.sync="isDelete">
            <p>Are you sure you want to remove this price margin?</p>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="marginsDeleteModalConfirmButton" class="btn btn-raised btn-primary" :disabled="loading" @click="remove">Delete</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog data-test="marginsAddNewModal" :is-open.sync="isOpen">
            <template v-if="isDuplicate">
                <h2 class="mt-0 font-weight-normal">Duplicate price margin table</h2>
                <p>You can change name and description, but margin table configuration will be cloned</p>
            </template>
            <h2 class="mt-0 font-weight-normal" v-else>{{ draft ? 'Edit price margin table' : 'Add new price margin table'}}</h2>
            <mercur-select v-model.trim="form.priceMarginType" :class="{'form-invalid': $v.form.priceMarginName.$error}">
                <span slot="label">Price margin type</span>
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.priceMarginType.required">Pricing margin type is required</span>
                </template>
                <option value="quantity">{{ type('quantity') }} Range</option>
                <option value="cost_price">{{ type('cost_price') }} Range</option>
            </mercur-select>
            <mercur-input data-test="marginModalNameInput" v-model.trim="form.priceMarginName" :class="{'form-invalid': $v.form.priceMarginName.$error}">
                Price margin table name
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.priceMarginName.required">Pricing margin name is required</span>
                    <span class="form-error" v-else-if="!$v.form.priceMarginName.unique">Pricing margin name needs to be unique</span>
                </template>
            </mercur-input>
            <mercur-input data-test="marginModalDescriptionInput" v-model.trim="form.priceMarginDescription">
                Price margin table description
            </mercur-input>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button v-if="isDuplicate" @click="submit" data-test="marginsModalDuplicateButton" class="btn btn-raised btn-primary" :disabled="loading">Duplicate</mercur-button>
                <mercur-button data-test="marginsModalSaveButton" v-else class="btn btn-raised btn-primary" @click="submit" :disabled="loading">
                    {{ draft ? 'Update price margin table' : 'Add new price margin table'}}
                </mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import PrettySelect from '../../components/utils/PrettySelect'
import { required, numeric, between, minValue, decimal } from 'vuelidate/lib/validators'
import { reverseStripProperties } from '@/components/utils/Utils'

const defaultForm = {
    priceMarginName: '',
    priceMarginDescription: '',
    priceMarginType: '',
    priceMarginTable: [],
}

export default {
    name: 'PriceMargins',
    components: { GridHeader, PrettySelect },
    data () {
        return {
            draft: null,
            isOpen: false,
            isEdit: false,
            isDuplicate: true,
            form: JSON.parse(JSON.stringify(defaultForm)),
            isDelete: false,
            loading: false,
            priceMargins: [],
            selectedPriceMargin: '',
            editingMarginTable: false,
            selectedMarginDraft: null,
            selectAll: false,
            selectedRows: [],
        }
    },
    validations: {
        form: {
            priceMarginName: {
                required,
                unique: function (value, vm) {
                    return this.checkIfUnique(vm)
                },
            },
            priceMarginType: {
                required,
            },
        },
        selectedPriceMargin: {
            priceMarginTable: {
                $each: {
                    to: {
                        numeric,
                        required,
                        minValue: minValue(0.01),
                        unique: function (value, vm) {
                            return this.checkIfToIsUnique(vm)
                        },
                    },
                    margin: {
                        required,
                        decimal,
                        between: between(-100, 100),
                    },
                },
            },
        },
    },
    watch: {
        selectedPriceMargin (value) {
            this.$router.push({
                name: 'PriceMargins',
                params: {
                    priceMarginId: value.priceMarginId,
                },
            })
        },
        selectAll (value) {
            if (value) {
                this.$set(this, 'selectedRows', this.selectedPriceMargin.priceMarginTable.map(e => e.temporaryId))
            } else {
                this.$set(this, 'selectedRows', [])
            }
        },
        selectedRows (value) {
            if (value.length === this.selectedPriceMargin.priceMarginTable.length) {
                this.$set(this, 'selectAll', true)
            }

            if (!value.length) {
                this.$set(this, 'selectAll', false)
            }
        },
    },
    methods: {
        type (value) {
            return CONFIG.TRANSLATIONS[value]
        },
        triggerAdd () {
            this.draft = null
            this.isOpen = true
            this.isDuplicate = false
        },
        triggerEdit () {
            this.draft = JSON.parse(JSON.stringify(this.selectedPriceMargin))
            this.form = this.draft
            this.isOpen = true
            this.isEdit = true
            this.isDuplicate = false
        },
        triggerClose () {
            this.draft = null
            this.isOpen = false
            this.isDuplicate = false
            this.isEdit = false
            this.form = JSON.parse(JSON.stringify(defaultForm))
            this.isDelete = false
        },
        triggerDuplicate () {
            this.isOpen = true
            this.isDuplicate = true
            this.form = JSON.parse(JSON.stringify(this.selectedPriceMargin))
        },
        triggerDelete () {
            this.isDelete = true
        },
        remove () {
            let url = CONFIG.API.ROUTES.PRICING.MARGIN.DELETE.replace('{priceMarginId}', this.selectedPriceMargin.priceMarginId)
            this.loading = true
            this.$api.delete(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Price margin was deleted',
                    type: 'success',
                })
                const index = this.priceMargins.findIndex(e => e.priceMarginId === data.data.priceMarginId)
                this.triggerClose()
                this.$delete(this.priceMargins, index)
                this.$set(this, 'selectedPriceMargin', '')
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: (err.data && err.data.exceptions && err.data.exceptions[0]) ? err.data.exceptions[0] : 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        submit () {
            this.$v.$touch()
            if (this.$v.form.$invalid) {
                return
            }

            if (this.isEdit) {
                this.updatePriceMargin()
                return
            }

            const url = CONFIG.API.ROUTES.PRICING.MARGIN.CREATE
            this.loading = true
            let payload = this.form
            if (this.form.priceMarginId) {
                payload = reverseStripProperties(payload, ['priceMarginName', 'priceMarginTable', 'priceMarginDescription', 'priceMarginType'])
            }

            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Price margin table was added',
                    type: 'success',
                })
                this.listPriceMargins()
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        transformMarginsToPercentage (items) {
            items.forEach((item, index) => {
                if (Array.isArray(item.priceMarginTable)) {
                    item.priceMarginTable.forEach((row, childIndex) => {
                        items[index].priceMarginTable[childIndex].margin = +(items[index].priceMarginTable[childIndex].margin * 100).toFixed(2)
                    })
                }
            })

            return items
        },
        listPriceMargins () {
            const url = CONFIG.API.ROUTES.PRICING.MARGIN.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                const items = this.transformMarginsToPercentage(data.data.items)
                this.$set(this, 'priceMargins', items)
                if (this.$route.params.priceMarginId) {
                    this.$set(this, 'selectedPriceMargin', this.priceMargins.find(e => e.priceMarginId === this.$route.params.priceMarginId))
                }
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching data',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        async updatePriceMargin (data) {
            const url = CONFIG.API.ROUTES.PRICING.MARGIN.UPDATE
            this.loading = true
            const payload = data || this.form
            await this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Price margin table was updated',
                    type: 'success',
                })

                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        checkIfUnique (vm) {
            let margins = JSON.parse(JSON.stringify(this.priceMargins))
            if (this.isEdit) {
                margins.splice(margins.findIndex(e => e.priceMarginId === this.selectedPriceMargin.priceMarginId), 1)
            }
            const previousNames = margins.map(e => e.priceMarginName)
            return !previousNames.includes(vm.priceMarginName)
        },
        checkIfToIsUnique (vm) {
            if (!this.selectedPriceMargin || !Array.isArray(this.selectedPriceMargin.priceMarginTable)) {
                return false
            }

            const tos = this.selectedPriceMargin.priceMarginTable.map(e => e.to)
            const numOfTos = tos.filter(e => e === vm.to)
            return !(numOfTos && numOfTos.length > 1)
        },

        // MARGINS METHODS
        updateMarginTable () {
            this.$v.$touch()
            if (this.$v.selectedPriceMargin.$invalid) {
                return
            }

            this.updatePriceMargin(this.selectedPriceMargin).then(() => {
                this.editingMarginTable = false
                this.selectedMarginDraft = null
                this.selectedRows = []
            })
        },
        triggerEditMargins () {
            if (this.editingMarginTable) {
                this.editingMarginTable = false
                this.triggerUndo()
                this.selectedMarginDraft = null
                return
            }

            this.editingMarginTable = true
            this.selectedMarginDraft = JSON.parse(JSON.stringify(this.selectedPriceMargin.priceMarginTable))
            this.addTemporaryIdsToMarginRules()
        },
        triggerUndo () {
            this.selectedPriceMargin.priceMarginTable = JSON.parse(JSON.stringify(this.selectedMarginDraft))
        },
        addTemporaryIdsToMarginRules () {
            this.selectedPriceMargin.priceMarginTable = this.selectedPriceMargin.priceMarginTable.map((e) => {
                return { ...e, temporaryId: this.$uuid.v4() }
            })
        },
        insertRowAbove (index) {
            this.selectedPriceMargin.priceMarginTable.splice(index, 0, {
                to: '',
                margin: '',
                temporaryId: this.$uuid.v4(),
            })
        },
        insertRowBelow (index) {
            this.selectedPriceMargin.priceMarginTable.splice(index + 1, 0, {
                to: '',
                margin: '',
                temporaryId: this.$uuid.v4(),
            })
        },
        deleteRow (temporaryId, isMultiple = false) {
            const index = this.selectedPriceMargin.priceMarginTable.findIndex(e => e.temporaryId === temporaryId)
            this.selectedPriceMargin.priceMarginTable.splice(index, 1)
            if (this.selectedRows.includes(temporaryId) && !isMultiple) {
                const selectedIndex = this.selectedRows.findIndex(e => e === temporaryId)
                this.selectedRows.splice(selectedIndex, 1)
            }
        },
        deleteSelectedRows () {
            this.selectedRows.forEach((temporaryId) => {
                this.deleteRow(temporaryId, true)
            })
            this.$set(this, 'selectedRows', [])
        },
    },
    created () {
        this.listPriceMargins()
    },
}
</script>

<style lang="scss" scoped>
    .percentage {
        position: absolute;
        left: 5px;
        top: 48%;
        transform: translateY(-50%);
        font-size: 10px;
        z-index: 1;
    }
</style>
