import moment from 'moment-business-days'

export function commaStringToArray (value) {
    return value.toString().split(',').map(value => value.trim())
}

export function stripProperties (value, keys = ['dateUpdated', 'dateCreated', 'dateRevoked']) {
    if (!value) {
        return value
    }
    keys.forEach(key => {
        delete value[key]
    })
    return value
}

export function reverseStripProperties (value, keys = []) {
    if (!value) {
        return value
    }

    const objectKeys = Object.keys(value)
    objectKeys.forEach(key => {
        if (!keys.includes(key)) {
            delete value[key]
        }
    })

    return value
}

/**
 * Returns the value for the given path on the object. If the path cannot be resolved returns the defaultReturn (null)
 *
 * @param path
 * @param object
 * @param defaultReturn
 * @returns {T}
 */
export function getDeepProperty (path, object, defaultReturn = null) {
    return path.split('.').reduce((traversingObject, property) => (traversingObject && traversingObject[property]) ? traversingObject[property] : defaultReturn, object)
}

export function addBusinessDaysFromHours (hours, date) {
    return moment(date).businessAdd(Math.ceil(hours / 24))
}

export function arraysEqualValues (arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false
        }
    }

    return true
}
